<template>
    <div class="copied-successfully" :class="isCopy ? 'active' : ''">
        {{ $t('generalTabs.linkHasBeenCopied') }}
    </div>
</template>

<script>
export default {
    props: {
        isCopy: Boolean
    }
};
</script>

<style scoped>
.copied-successfully {
    font-family: 'Wix Madefor Display', sans-serif;
    position: absolute;
    bottom: -35px;
    font-size: 13px;
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    color: white;
    padding: 12px 16px;
    z-index: 100;
    border-radius: 8px;
    transition: 0.2s all;
    opacity: 0;
}

.copied-successfully.active {
    opacity: 1;
}
</style>